<template>
  <div>
    <select
      v-if="list && list.length > 0"
      class="form-control form-control-sm shadow-none"
      v-model="user"
      @change="filter"
    >
      <option value="all">все СЦ</option>
      <option v-for="(item, idx) in list" :key="idx" :value="item.id">{{
        item.user
      }}</option>
    </select>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SelectRepairs",
  props: ["repairs_list"],
  computed: {
    ...mapGetters(["SELECTREPAIRSUSER"]),
    user: {
      get() {
        return this.SELECTREPAIRSUSER;
      },
      set(value) {
        this.set_user(value);
      }
    }
  },
  data: () => ({
    list: [],
    id: []
  }),
  mounted() {
    this.repairs_list.map(item => {
      if (!this.id.includes(item.id_user)) {
        this.id.push(item.id_user);
        this.list.push({ id: item.id_user, user: item.user });
      }
      this.list.sort((a, b) => (a.user > b.user ? 1 : -1));
    });
  },
  methods: {
    ...mapMutations(["set_user"]),
    filter() {
      this.$emit("update");
    }
  }
};
</script>
<style scoped></style>
