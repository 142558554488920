<template>
  <div>
    <div v-if="repairs_list && repairs_list.length">
      <div class="btn-wrapper row">
        <button
          class="btn btn-sm btn-outline-success shadow-none"
          @click="createOrder"
        >
          создать заявку по всем созданным ремонтам
        </button>
        <SelectRepairs
          v-if="repairs_list && USER_INFO.status === 'admin'"
          :repairs_list="repairs_list"
          @update="update"
          :key="keySelectUsers"
        />
      </div>
      <div class="overflow-auto">
        <table class="table table-sm text-center table_response">
          <thead>
            <tr>
              <th></th>
              <th>№</th>
              <th v-if="USER_INFO.status === 'admin'"></th>
              <th>№ СЦ</th>
              <th v-if="USER_INFO.status === 'admin'">СЦ</th>
              <th>создан</th>
              <th>инструмент</th>
              <th>группа</th>
              <th>S№</th>
              <th>дата продажи</th>
              <th>дата принятия в ремонт</th>
              <th v-if="USER_INFO.status === 'admin'"></th>
              <th>дефект</th>
              <th>продавец</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="tr"
              v-for="(item, idx) in repairs_list"
              :key="idx"
              :class="item.no_read_messages > 0 ? 'message' : ''"
            >
              <td>{{ idx + 1 }}</td>
              <td
                v-if="USER_INFO.status === 'admin'"
                title="подтвердить ремонт"
              >
                <button
                  class="btn btn-sm btn-outline-success shadow-none btn-confirm"
                  @click="confirm_repair(item)"
                >
                  <i class="bi bi-check"></i>
                </button>
              </td>
              <td class="nowrap">
                <div
                  :class="
                    item.who_saw && USER_INFO.status === 'admin'
                      ? 'who-saw'
                      : ''
                  "
                >
                  {{ item.repair_number }}
                </div>
                <div v-if="item.who_saw_all && USER_INFO.status === 'admin'">
                  <div
                    v-for="(item_who, idx) in item.who_saw_all"
                    :key="idx + 'who_saw'"
                  >
                    {{ item_who.user }}
                  </div>
                </div>
                <div
                  v-if="
                    item.duplicate_serial_number && USER_INFO.status === 'admin'
                  "
                >
                  <a
                    :href="
                      `https://sc-kalibr.ru/repairs/repair/${item.duplicate_serial_number}`
                    "
                    target="_blank"
                    @click.prevent="view_duplicate(item)"
                    >дубликат {{ item.duplicate_serial_number_count }}шт.</a
                  >
                </div>
              </td>
              <td @click="goRepair(item.remont_id)">
                {{ item.receiptNumber }}
              </td>
              <td
                @click="goRepair(item.remont_id)"
                v-if="USER_INFO.status === 'admin'"
              >
                {{ item.user }}
              </td>
              <td @click="goRepair(item.remont_id)">{{ item.date_add }}</td>
              <td @click="goRepair(item.remont_id)">{{ item.name_instr }}</td>
              <td @click="goRepair(item.remont_id)">
                {{ item.serialNumberGroup }}
              </td>
              <td @click="goRepair(item.remont_id)">{{ item.serialNumber }}</td>
              <td @click="goRepair(item.remont_id)">{{ item.dateSale }}</td>
              <td @click="goRepair(item.remont_id)">{{ item.dateRepair }}</td>
              <td
                @click="goRepair(item.remont_id)"
                v-if="USER_INFO.status === 'admin'"
                :class="
                  getDayFromDateRepair(item.dateRepairSql).panic &&
                    'text-danger font-weight-bold'
                "
              >
                {{ getDayFromDateRepair(item.dateRepairSql).countDay }}
              </td>
              <td @click="goRepair(item.remont_id)">{{ item.defect }}</td>
              <td @click="goRepair(item.remont_id)">{{ item.seller }}</td>
              <td @click="goRepair(item.remont_id)">
                <i class="bi bi-envelope-fill" v-if="item.no_read_messages > 0"
                  ><a href="#" class="badge badge-danger"></a
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else />
    <DuplicateViews
      v-if="USER_INFO.status === 'admin'"
      :open="openDialog"
      :repairsId="duplicateId"
    />
  </div>
</template>
<script>
import { HTTPGET, HTTPGETNOLOADER } from "@/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import SelectRepairs from "../../components/repairs/SelectRepairs.vue";
import DuplicateViews from "@/components/DuplicateViews.vue";
export default {
  name: "RepairsList",
  components: { SelectRepairs, DuplicateViews },
  computed: {
    ...mapGetters([
      "USER_INFO",
      "PATH",
      "SELECTREPAIRSUSER",
      "REPAIRS_LIST_SCROLL"
    ])
  },
  data: () => ({
    repairs_list: null,
    interval: null,
    keySelectUsers: 0,
    openDialog: false,
    duplicateId: null
  }),
  mounted() {
    this.ADDPATH("созданные ремонты"),
      this.get_repair_list(HTTPGET),
      this.get_repair_list_no_loader(HTTPGETNOLOADER);
    console.log(new Date().getTime());
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    ...mapMutations(["set_repairs_list_scroll"]),
    goRepair(id) {
      this.$router.push(
        `/repairs/repair/${id}?path=${this.PATH}&from=new_repairs&user_id=${this.SELECTREPAIRSUSER}`
      );
      this.set_repairs_list_scroll(window.pageYOffset);
    },
    get_repair_list(http) {
      http({
        url: "repairs/repairs_list.php",
        params: {
          id: this.SELECTREPAIRSUSER
        }
      })
        .then(resp => (this.repairs_list = resp.data.repairs))
        .then(() => (this.keySelectUsers += 1))
        .then(() => window.scrollBy(0, this.REPAIRS_LIST_SCROLL))
        .then(() => this.set_repairs_list_scroll(0));
    },
    get_repair_list_no_loader(http) {
      this.interval = setInterval(() => {
        http({
          url: "repairs/repairs_list.php",
          params: {
            id: this.SELECTREPAIRSUSER
          }
        }).then(resp => (this.repairs_list = resp.data.repairs));
      }, 5000);
    },
    createOrder() {
      if (this.repairs_list.length) {
        this.$router.push(`/repairs/new-repairs-order`);
      }
    },
    update() {
      this.get_repair_list(HTTPGET);
    },
    getDayFromDateRepair(dateRepairSql) {
      const start = new Date(dateRepairSql).getTime();
      const end = new Date().getTime();
      const oneDay = 1000 * 60 * 60 * 24;
      const diffInTime = end - start;
      const diffInDays = Math.round(diffInTime / oneDay);
      let panic = "";
      if (diffInDays > 10) {
        panic = "red";
      }
      return { countDay: diffInDays, panic };
    },
    view_duplicate(id) {
      this.duplicateId = id;
      this.openDialog = !this.openDialog;
    },
    confirm_repair(item) {
      if (confirm("ремонт будет подтвержден")) {
        HTTPGET({
          url: "repairs/confirm_repair.php",
          params: {
            id: item.remont_id
          }
        }).then(() => this.get_repair_list(HTTPGET));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
}
.table-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.7em;
  padding: 3px;
  text-align: center;
  margin-top: 10px;
}
th > input {
  width: 0;
  height: 1.6em;
  margin: auto;
}
th {
  text-align: center;
  vertical-align: middle;
}
td {
  vertical-align: middle;
}
.tr:hover {
  cursor: pointer;
  background: rgb(247, 247, 247);
}
.bi-envelope-fill {
  font-size: 2em;
  color: red;
}
.message {
  color: red;
}
.nowrap {
  white-space: nowrap;
}
.btn-wrapper {
  margin: 10px;
}
.btn {
  margin-right: 10px;
  margin-bottom: 5px;
}
.who-saw {
  background: green;
  color: white;
}
.btn-confirm {
  padding: 0 2px;
  margin: 0;
}
</style>
